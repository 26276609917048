import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  ModalCloseButton,
  ModalBody,
  Button,
  VStack,
  Box,
} from "@chakra-ui/react";
import { useUser } from "../hooks/useUser";
import { useState } from "react";

export function ModalFirstTime() {
  const [hasClosed, setHasClosed] = useState(false);
  const { data, updateUser, isLoading } = useUser();

  const { isOpen, onClose } = useDisclosure({
    isOpen: !isLoading && !hasClosed && !data?.firstLoginAt,
    onClose: async () => {
      await updateUser({ hasFirstLogin: true });
      setHasClosed(true);
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW="90%">
        <ModalCloseButton />
        <ModalBody textAlign="center">
          <VStack spacing="8" p="8">
            <Text fontSize={64}>🎉</Text>
            <Text as="b" fontSize="xl" color="green">
              Bienvenue sur Road Roamer !
            </Text>
            <Text>
              Vous bénéficiez de toutes les fonctionnalités pendant la durée de
              votre période d'essai.
            </Text>
            <Box
              borderWidth="medium"
              borderRadius="lg"
              borderColor="green"
              px="2"
              py="1"
            >
              <Text as="b" color="green">
                7 jours gratuits
              </Text>
            </Box>
            <Text>
              Lancez votre premier trajet et découvrez les potentiels dangers.
            </Text>
            <Button variant="primary" onClick={onClose}>
              C'est parti !
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
