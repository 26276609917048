import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  Text,
  ModalBody,
  Button,
  VStack,
  Image,
} from "@chakra-ui/react";
import { useUser } from "../hooks/useUser";
import { useNavigate } from "react-router-dom";

export function ModalSubscriptionEnded() {
  const navigate = useNavigate();
  const { data, isLoading } = useUser();

  const { isOpen, onClose } = useDisclosure({
    isOpen: !data?.activeSubscription,
    onClose: () => {},
  });

  if (isLoading) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="xs">
      <ModalOverlay />
      <ModalContent>
        <ModalBody textAlign="center">
          <VStack spacing="8" p="8">
            <Image
              src={"https://storage.googleapis.com/roadroamer/logo.png"}
              alt="logo"
            />
            <Text fontSize={64}>😞</Text>
            <Text as="b" fontSize="xl" color="green">
              Votre période d'essai est terminée...
            </Text>
            <Text>
              Découvrez maintenant nos abonnements pour profiter de Road Roamer
              en illimité !
            </Text>
            <Button variant="primary" onClick={() => navigate("/subscription")}>
              Continuer
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
